import { createContext, ReactNode, useContext } from 'react';
import { CustomColor } from '../orgs/orgTypes';
import { getOrgColors } from '../orgs/helpers';
import { UserContext } from './UserStateManager';
import { useQuery } from 'react-query';
import { COLORS, WHITE } from '../Enums';

type OrgColorContextType = {
  customColorList: CustomColor[];
  isLoading: boolean;
  mapColorValues: (value?: string[] | number[][]) => CustomColor[];
  refetch: () => void;
};

const nothing = () => {};

export const OrgColorContext = createContext<OrgColorContextType>({
  customColorList: [],
  isLoading: false,
  mapColorValues: () => {
    return [] as CustomColor[];
  },
  refetch: nothing,
});

export function OrgColorContextProvider({ children }: { children: ReactNode }) {
  const userContext = useContext(UserContext);

  const {
    data: orgColorList,
    refetch,
    isLoading,
  } = useQuery(`orgColors-${userContext.state.currentOrg?.org?._id}`, () =>
    getOrgColors(userContext),
  );

  const mapColorValues = (value?: string[] | number[][]) => {
    if (value === undefined) return [WHITE];

    return value.map((val: string | number[]) => {
      const orgColor = orgColorList?.find((c: CustomColor) => c._id === val);
      return (
        orgColor ??
        COLORS.find(
          (c) =>
            c.value[0] === val[0] &&
            c.value[1] === val[1] &&
            c.value[2] === val[2],
        ) ??
        WHITE
      );
    });
  };

  //use the previous type to pass into "value" for the Provider
  const providerValue: OrgColorContextType = {
    customColorList: orgColorList ?? [],
    isLoading,
    mapColorValues,
    refetch,
  };

  return (
    <OrgColorContext.Provider value={providerValue}>
      {children}
    </OrgColorContext.Provider>
  );
}
