import { AppBar, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useContext, useState, useEffect } from 'react';
import DrawerContent from './DrawerContent';
import colorwashLogo from '../../assets/images/color-wash-logo-vertical.png';
import { UserContext } from '../context/UserStateManager';
import { useHistory } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';

const drawerWidth = 295;

function checkPathForTitle(path: string, orgName: string | undefined) {
  if (path === '/admin') return 'Admin';
  if (path === '/user') return 'Profile';
  if (path === '/register') return 'Register Controller';
  else return orgName ?? '';
}

function Navigation() {
  const userCon = useContext(UserContext);
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Media query for mobile devices
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // Media query for desktop
  const [mobileOpen, setMobileOpen] = useState(false); // Mobile drawer open state
  const [title, setTitle] = useState(
    checkPathForTitle(
      history.location.pathname,
      userCon.state.currentOrg?.org?.name,
    ),
  );

  useEffect(() => {
    setTitle(
      checkPathForTitle(
        history.location.pathname,
        userCon.state.currentOrg?.org?.name,
      ),
    );
    return history.listen((location) =>
      setTitle(
        checkPathForTitle(
          location.pathname,
          userCon.state.currentOrg?.org?.name,
        ),
      ),
    );
  }, [userCon.state.currentOrg]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar
        color="secondary"
        sx={{
          [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
          },
          height: `68px`,
          borderBottom: '1px solid #e0e0e0',
        }}
        position="fixed"
        elevation={0}
      >
        <Toolbar
          sx={{
            display: 'flex',
            [theme.breakpoints.up('md')]: {
              justifyContent: 'space-between',
            },
          }}
        >
          {isMobile && (
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle} // Toggle mobile drawer
              sx={{
                display: { md: 'none' }, // Hide on desktop
              }}
            >
              <MenuIcon style={{ fontSize: '3rem', color: 'black' }} />
            </IconButton>
          )}
          <Typography variant="h1">{title}</Typography>
        </Toolbar>
      </AppBar>

      {/* Navigation Drawer */}
      <nav
        style={{
          width: isDesktop ? drawerWidth : 'auto',
          flexShrink: 0,
        }}
        aria-label="navigation drawers"
      >
        {/* Mobile Drawer */}
        {isMobile && (
          <Drawer
            container={document.body}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: 'block', md: 'none' }, // Only visible on mobile
              '& .MuiDrawer-paper': { width: drawerWidth },
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile
            }}
          >
            <DrawerContent closeDrawer={handleDrawerToggle} />
            <img
              style={{
                marginLeft: theme.spacing(4),
                marginRight: theme.spacing(4),
                width: drawerWidth - parseInt(theme.spacing(8)),
                marginTop: 'auto',
                marginBottom: theme.spacing(4),
              }}
              src={colorwashLogo}
              alt="Colorwash"
            />
          </Drawer>
        )}

        {/* Desktop Drawer */}
        {isDesktop && (
          <Drawer
            sx={{
              display: { xs: 'none', md: 'block' }, // Only visible on desktop
              '& .MuiDrawer-paper': { width: drawerWidth },
            }}
            variant="permanent"
            open
          >
            <DrawerContent />
            <img
              style={{
                marginLeft: theme.spacing(4),
                marginRight: theme.spacing(4),
                width: drawerWidth - parseInt(theme.spacing(8)),
                marginTop: 'auto',
                marginBottom: theme.spacing(4),
              }}
              src={colorwashLogo}
              alt="Colorwash"
            />
          </Drawer>
        )}
      </nav>
    </>
  );
}

export default Navigation;
